var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "admin-system-control" }, [
    _c("div", { staticClass: "toolbar justify-content-between" }, [
      _c("div", [
        _c("i", {
          directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
          staticClass: "icon-button",
          class: _vm.$config.icons.general.back,
          attrs: { title: "Back to Admin" },
          on: { click: _vm.clickBack }
        })
      ])
    ]),
    _c(
      "div",
      {},
      [
        _c(
          "b-card",
          [
            _c("b-card-header", [_c("h2", [_vm._v("System Controls")])]),
            _c("b-card-body", { staticClass: "main" }, [
              _c(
                "div",
                { staticClass: "panel-container p-3" },
                [
                  _c(
                    "b-card",
                    { staticClass: "panel-item" },
                    [
                      _c("b-card-header", [_vm._v("Info")]),
                      _c("b-card-body", { staticClass: "panel-item-body" }, [
                        _vm._v(
                          " This control panel is a collection of admin tools that are expected to be *very* low use. Some of them affect the entire system/user-base. Consider before using them. "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { staticClass: "panel-item" },
                    [
                      _c("b-card-header", [
                        _vm._v("Reset ALL User Notification Rules")
                      ]),
                      _c(
                        "b-card-body",
                        { staticClass: "panel-item-body" },
                        [
                          _vm._v(
                            " Reset ALL User's Notification Rules to the default Values. "
                          ),
                          _vm.notificationResetResult
                            ? _c("div", { staticClass: "flex-column" }, [
                                _c("label", { staticClass: "green-label" }, [
                                  _vm._v("Reset Result:")
                                ]),
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.notificationResetResult) +
                                      " "
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _c(
                            "b-button",
                            {
                              staticClass: "button",
                              on: {
                                click: function($event) {
                                  return _vm.clickResetNotifications()
                                }
                              }
                            },
                            [_vm._v("Reset Notification Rules")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }