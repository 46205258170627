<template>
  <div class="admin-system-control">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
    </div>
    <div class="">
      <b-card>
        <b-card-header><h2>System Controls</h2></b-card-header>
          <b-card-body class="main">
            <div class="panel-container p-3">
              <b-card class=" panel-item">
                <b-card-header>Info</b-card-header>
                <b-card-body class="panel-item-body">
                  This control panel is a collection of admin tools that are expected to be *very* low use. Some of them
                  affect the entire system/user-base. Consider before using them.
                </b-card-body>
              </b-card>
              <b-card class=" panel-item">
                <b-card-header>Reset ALL User Notification Rules</b-card-header>
                <b-card-body class="panel-item-body">
                  Reset ALL User's Notification Rules to the default Values.
                  <div v-if="notificationResetResult" class="flex-column">
                    <label class="green-label">Reset Result:</label>
                    <div>
                      {{notificationResetResult}}
                    </div>
                  </div>
                  <b-button class="button" @click="clickResetNotifications()">Reset Notification Rules</b-button>
                </b-card-body>
              </b-card>
            </div>
          </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import * as ErrorHelper from '../../components/helpers/ErrorHelper'
import * as DataProvider from '../../components/helpers/DataProvider'
import * as AlertHelper from '../../components/helpers/AlertHelper'
import moment from 'moment'

export default {
  name: 'admin-system-control',
  components: {
  },
  data: function () {
    return {
      notificationResetResult: null
    }
  },
  created () {
  },
  methods: {
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    },
    clickResetNotifications: async function () {
      let confirm = await this.$bvModal.msgBoxConfirm('This will reset ALL User Notification Rules on this ' +
        'system. Old rules cannot be recovered. Are you SURE?', {
        title: 'Reset ALL User Notification Rules?',
        centered: true,
        okVariant: 'danger'
      })

      if (confirm) {
        let result = await DataProvider.adminResetAllUserNotificationRules()
        console.log('Result: ', result.data)
        if (result.success) {
          // {'complete': True, 'n_users': n_users, 'n_devices': n_devices, "n_errors" : n_errors}
          this.notificationResetResult = `Users Affected: ${result.data.n_users} Devices Affected: ${result.data.n_devices}
           Errors: ${result.data.n_errors}`
          AlertHelper.successToast('User Notification Rules have been Reset!', 'Success!')
        } else {
          ErrorHelper.displayDataErrorToast(result)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../variables';

  .admin-system-control {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
    overflow-y: auto;
  }

  .toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 3rem;
  }

  .main {
    min-height: 78vh;
  }

  .panel-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .panel-item {
    margin: 10px 10px;
  }

  .panel-item-body {
    padding: 5px;
  }

  //.panel{
  //  background: $theme-color-background-4;
  //  border-radius: 10px;
  //  min-height: 50%;
  //}
  //
  //.panel-heading {
  //  color: $theme-color-primary-2;
  //  font-weight: 600;
  //}

</style>
